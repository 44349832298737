import $ from 'jquery';


export default class SlickSlider {
    init() {
        $('[data-slick]').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                prevArrow:"<button type=\"button\" class=\"btn-reset slick-prev slick-arrow\"><svg class=\"svgicon\"><use xlink:href=\"#icon-chevron-left\"></use></svg><span class=\"sr-only\">Prev</span></button>",
                nextArrow:"<button type=\"button\" class=\"btn-reset slick-next slick-arrow\"><svg class=\"svgicon\"><use xlink:href=\"#icon-chevron-right\"></use></svg><span class=\"sr-only\">Next</span></button>"
            });
        });
    }

}
