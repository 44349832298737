import $ from 'jquery';
import prestashop from 'prestashop';

var headerStickyHeight = 0;

$(document).ready(() => {

    // Fix orribile per evitare che l'editor di Elementor si "rompa"
    if ( window.location !== window.parent.location ) {
        // La pagina è in un iframe, quindi nell'anteprima dell'editor di Elementor
        $('.brands-carousel').remove();
    }

    // Smooth scroll
    $( "a.js-scroll-to" ).click(function( event ) {
        event.preventDefault();
        if ($($(this).attr("href")).length) {
            $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top - 20}, 500);
        }
    });

    // Elementor override
    $('.elementor-image-carousel').on('init breakpoint', function(slick){
        var carouselOptions = {
            prevArrow:'<svg class="svgicon"><use xlink:href="#icon-chevron-left"></use></svg>',
            nextArrow:'<svg class="svgicon"><use xlink:href="#icon-chevron-right"></use></svg>',
        }
        $('.elementor-image-carousel .slick-arrow.slick-prev').html(carouselOptions.prevArrow);
        $('.elementor-image-carousel .slick-arrow.slick-next').html(carouselOptions.nextArrow);
    });

    // Elementor categories carousel in homepage
    $('#categories-carousel .elementor-widget-wrap').slick({
        dots: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        mobileFirst: true,
        prevArrow: '<button type="button" class="btn-reset slick-prev slick-arrow"><svg class="svgicon"><use xlink:href="#icon-chevron-left"></use></svg><span class="sr-only">Prev</span></button>',
        nextArrow: '<button type="button" class="btn-reset slick-next slick-arrow"><svg class="svgicon"><use xlink:href="#icon-chevron-right"></use></svg><span class="sr-only">Next</span></button>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: true,
                    slidesToShow: 4
                }
            }
        ]
    });

    // Iubenda
    $(document).ready(() => {
        if ($('.iubenda-embed.iub-body-embed').length) {
            (function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
        }
    });

    // Move reviews on product page
    //$('#js-product-comments').appendTo($('#product-reviews-collapse')).removeClass('hidden');

    // Risultati di ricerca dell'assistente virtuale: nascondo colonna dei filtri
    if ($('body.as4-search-results-2').length && $('#left-column').length) {
        $('#left-column').remove();
        $('#content-wrapper').removeClass('col-lg-9');
        $('#js-filters-toggle').remove()
    }
})


$(window).on("load", function() {
    // Sticky header on scroll-up
    if ($('body').attr('id') !== 'checkout') {
        var $window       = $(window);
        var lastScrollTop = 0;
        var $header       = $('#header');
        var headerHeight  = $header.outerHeight();
        var headerBottom  = $header.position().top + $header.outerHeight(true);
        $window.scroll( function() {
            var windowTop  = $window.scrollTop();
            if (windowTop >= headerBottom) {
                $('body').css('padding-top', headerHeight + 'px');
                $header.addClass('header-sticky');
            } else {
                $header.removeClass('header-sticky');
                $header.removeClass('header-sticky--show');
                $('body').css('padding-top', '0');
            }
            if ($header.hasClass('header-sticky')) {
                if (windowTop <= headerBottom || windowTop < lastScrollTop) {
                    $header.addClass('header-sticky--show');
                    headerStickyHeight = $header.outerHeight();
                } else {
                    $header.removeClass('header-sticky--show');
                    headerStickyHeight = 0;
                }
            }
            lastScrollTop = windowTop;
        });
    }
})

$(document).on('scroll', function(){
    if (!$('body#contact').length) {
        // Sticky contact box
        var stickyContacts = $('#js-sticky-contacts');
        if($(window).scrollTop() + window.innerHeight > $(document).height() - 200) {
            //Bottom Reached
            stickyContacts.fadeOut();
        } else {
            // Show only after first scroll
            stickyContacts.fadeIn();
        }
    }
});

// Touch screens
function is_touch_enabled() {
    return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ||
        ( navigator.msMaxTouchPoints > 0 );
}
if( is_touch_enabled() ) {
    //console.log('touch device');
    $('body').addClass('touch-events')
} else {
    $('body').addClass('no-touch-events')
}

// Readmore
if (typeof $.fn.readmore === 'function') {
    if ($(window).width() < prestashop.responsive.min_width) {
        var readerMobile = $('.js-readmore-mobile').readmore({
            embedCSS: false,
            collapsedHeight: 150,
            moreLink: '<a href="#"><span>' + lReadMore + '</span> +</a>',
            lessLink: '<a href="#"><span>' + lClose + '</span> -</a>'
        });
    }
}